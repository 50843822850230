//React
import React from 'react';
//Components
import Main from '../Components/Main/Main';

const Home = () => {
  return (
    <>
      <Main />
    </>
  );
};

export default Home;
