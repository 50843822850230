//React
import React from 'react';
//Components
import MainFavorite from '../Components/MainFavorite/MainFavorite';

const Home = () => {
  return (
    <>
      <MainFavorite />
    </>
  );
};

export default Home;
